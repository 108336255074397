import Accordion from 'react-bootstrap/Accordion';

import preschool1 from '../assets/images/services/pre-school-program2.jpg'
import preschool2 from '../assets/images/services/pre-school-oak.png'
import structured from '../assets/images/services/structured-earning.svg'
import numeracy from '../assets/images/services/numeracy-skills.svg'
import critical from '../assets/images/services/critical.svg'
import character from '../assets/images/services/character.svg'
import themed from '../assets/images/services/themed.svg'
import parent from '../assets/images/services/parent.svg'

import Layout from '../components/layout';
import ScrollToTop from '../assets/js/Scrolltotop'
import { Helmet } from 'react-helmet'

const Preschool = () => {
    return (
        <>
            <ScrollToTop />
            <div id="page-section">
                <Layout title="PreSchool Program in Oak Harbor | Nurture With Care Kids Academy" description="Discover the best early learning preschool with a nurturing environment and experienced teachers at Nurture With Care Kids Academy. Enroll your child today.">
                    <Helmet>
                        <meta property="og:site_name" content="Nurture With Care Kids Academy" />
                        <meta property="og:url" content="https://nurturewithcarekidsacademy.com/programs/preschool-in-oak-harbor" />
                        <meta property="og:title" content="Preschool Program in Oak Harbor" />
                        <meta property="og:type" content="article" />
                        <meta property="og:description" content="Discover the best early learning preschool with a nurturing environment and experienced teachers at Nurture With Care Kids Academy. " />
                        <meta property="og:image" content="https://nurturewithcarekidsacademy.com/static/media/preschool.3d02f9e76d3c797fc013.jpg" />

                        <script type="application/ld+json">
                            {JSON.stringify(
                                {
                                    "@context": "https://schema.org",
                                    "@type": "FAQPage",
                                    "mainEntity": [{
                                        "@type": "Question",
                                        "name": "What skills are preschool children actually learning in the dramatic play center?",
                                        "acceptedAnswer": {
                                            "@type": "Answer",
                                            "text": "The preschoolers' social skills, language skills, and imagination in pretend play are developed through dramatic play centers at Nurture with Care Kids Academy. Children creatively express themselves and collaborate, offering negotiation."
                                        }
                                    }, {
                                        "@type": "Question",
                                        "name": "What is not one of the key features of a highly structured preschool program?",
                                        "acceptedAnswer": {
                                            "@type": "Answer",
                                            "text": "Typically, highly structured preschool programs place a lot of emphasis on organized activities, socialization, and cognitive development, but they do not prioritize flexibility and spontaneity which are central to developing creativity among children. Not like the value attached to early childhood development programs by Nurture with Care Kids Academy which is well known for its innovation, some daycares in Oak Harbor WA may forget this part and do not give the best toddler daycare."
                                        }
                                    }, {
                                        "@type": "Question",
                                        "name": "How do preschool programs account for all children in an emergency?",
                                        "acceptedAnswer": {
                                            "@type": "Answer",
                                            "text": "There are emergency procedures ready to ensure all children are safe at Nurture with Care Kids Academy. These procedures at our preschool programs near Oak Harbor include evacuation drills or even head count protocols."
                                        }
                                    }, {
                                        "@type": "Question",
                                        "name": "How to apply for the preschool program?",
                                        "acceptedAnswer": {
                                            "@type": "Answer",
                                            "text": "To enroll in our preschool learning center, visit our website or contact us directly. You will need to fill out and submit the online application form and any other necessary papers. From that point, we will guide you through everything while you ask questions."
                                        }
                                    }, {
                                        "@type": "Question",
                                        "name": "What is the duration of the preschool program?",
                                        "acceptedAnswer": {
                                            "@type": "Answer",
                                            "text": "The duration of our preschools in Oak Harbor WA, is from 9 am to 4 pm. This time frame allows for a complete curriculum in varied subjects and activities that promote intellectual development, social skills, and emotional well-being."
                                        }
                                    }, {
                                        "@type": "Question",
                                        "name": "What are some tips for preparing your child for preschool?",
                                        "acceptedAnswer": {
                                            "@type": "Answer",
                                            "text": "Some things you can do to prepare your child for early learning preschool include having routines, reading books on school-going, and encouraging independence in simple tasks like dressing or eating. Just before the child begins attending preschool, invite friends over so that they may become familiar with strangers."
                                        }
                                    }, {
                                        "@type": "Question",
                                        "name": "What are some common concerns that parents have about preschool?",
                                        "acceptedAnswer": {
                                            "@type": "Answer",
                                            "text": "Parents' common issues about Oak Harbor preschool education include separation anxiety, academic preparation, safety, and socialization. They may be concerned whether their child will fit in the new setting and mingle with his mates as expected to develop himself. The concern for separation anxiety, academic readiness, safety, and socialization among parents who take their children to Oak Harbor preschool education is addressed by Nurture with Care Kids Academy through open communication that eases their worry about their child’s adjustment and development."
                                        }
                                    }, {
                                        "@type": "Question",
                                        "name": "What are some signs that your child has difficulty adjusting to preschool?",
                                        "acceptedAnswer": {
                                            "@type": "Answer",
                                            "text": "Some signals that your kid might have problems adjusting to preschool consist of being overly possessive at drop-off time, withdrawing from activities or companions while the early childhood development programs, excessive crying or tantrums, physical symptoms such as headaches or stomach aches, and developmental regression signs. Early intervention is possible by speaking to teachers and observing your children's behavior."
                                        }
                                    }]
                                }

                            )}</script>
                    </Helmet>

                    <section className="services" style={{ paddingTop: "0" }}>
                        <div className="container-fluid">
                            <div className="jumbo-heading">
                                <h1>Preschool Program in Oak Harbor</h1>
                            </div>
                            <div className="container ">
                                <div className="row">
                                    <div className="col-md-6">
                                        <p>Welcome to our preschool learning center, an early learning preschool in Oak Harbor, WA, dedicated to nurturing young minds through an enriching and comprehensive curriculum.
                                        </p><p>
                                            At our center, structured learning forms the backbone of our approach. Our curriculum spans language, math, science, and more, offering age-appropriate activities that inspire and challenge children, ensuring a well-rounded educational experience.
                                        </p>
                                        <p>
                                            Early literacy and numeracy skills are foundational in our program. We place a strong emphasis on building these essential skills, fostering a solid base for academic success. Through engaging activities tailored to their developmental stage, children develop a love for reading, numeracy, and learning.
                                        </p>
                                        <p>Encouraging critical thinking and problem-solving abilities is integral to our curriculum. Hands-on activities, puzzles, and games stimulate cognitive growth, fostering a mindset that values inquiry, exploration, and finding solutions.</p>
                                    </div>
                                    <div className="col-md-6">
                                        <img src={preschool1} alt="preschool in oak harbor" className="img-curved  center-block img-responsive" />
                                    </div>
                                </div>
                                <div className="row mt-30">
                                    <div className="col-md-6">
                                        <img src={preschool2} alt="preschool in oak harbor" className="img-curved  center-block img-responsive" />
                                    </div>
                                    <div className="col-md-6 mt-3">
                                        <p>
                                            Character development is woven into our daily activities. Through stories, discussions, and cooperative projects, we cultivate traits such as kindness, empathy, and responsibility, nurturing children to become well-rounded individuals with strong moral values.
                                        </p>
                                        <p>
                                            Project-based learning forms an integral part of our approach. Themed projects encourage research, collaboration, and creativity, providing children with opportunities to explore various subjects and develop a diverse skill set.
                                        </p>
                                        <p>
                                            We understand the importance of parent involvement in a child's educational journey. Our center maintains open communication with parents/guardians, providing regular updates on their child's progress and involving families in various aspects of the learning process, ensuring a collaborative and supportive environment.
                                        </p>
                                        <p>As one of the leading <a href="https://nurturewithcarekidsacademy.com/">daycares in Oak Harbor, WA</a>, our preschool learning center is dedicated to providing a nurturing and stimulating environment where children flourish academically, socially, and emotionally. We strive to instill a love for learning and lay a strong foundation for lifelong success.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>

                    <section className="facilities bg-color4">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-12 text-center">
                                    <h2>Facilities for Preschoolers </h2>
                                    <p>Nurture With Care Kids Academy provides everything parents want in an early learning preschool for their children. We feel proud of our dedicated teachers, who understand children’s different educational needs and are committed to providing age-appropriate education and the best care in a more positive and safe environment. Our <a href="https://nurturewithcarekidsacademy.com/programs/young-toddlers-in-oak-harbor">early childhood learning center</a> holds a reputation in the field of early education, learning, and human development. Let’s check out the facilities provided at Nurture with Care Kids Academy for Preschoolers!
                                    </p>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-4">
                                    <div className="serviceBox-1 bg-color2">
                                        <img src={structured} className="img-responsive" alt="toddler childcare activities" />
                                        <h3>Structured Learning</h3>
                                        <p>Our curriculum follows a structured approach that encompasses language, math, science, and more. Children engage in age-appropriate activities that challenge and inspire them.
                                        </p>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="serviceBox-1 bg-color2">
                                        <img src={numeracy} className="img-responsive" alt="toddler childcare activities" />
                                        <h3>Literacy and Numeracy</h3>
                                        <p> We place a strong emphasis on building early literacy and numeracy skills, fostering a solid foundation for academic success.</p>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="serviceBox-1 bg-color2">
                                        <img src={critical} className="img-responsive" alt="toddler childcare activities" />
                                        <h3>Critical Thinking</h3>
                                        <p>Our program encourages critical thinking and problem-solving through hands-on activities, puzzles, and games that stimulate cognitive growth.
                                        </p>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="serviceBox-1 bg-color2">
                                        <img src={character} className="img-responsive" alt="toddler childcare activities" />
                                        <h3>Character Development</h3>
                                        <p> We foster character traits such as kindness, empathy, and responsibility through stories, discussions, and cooperative projects.
                                        </p>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="serviceBox-1 bg-color2">
                                        <img src={themed} className="img-responsive" alt="toddler childcare activities" />
                                        <h3>Project-Based Learning</h3>
                                        <p> Children participate in themed projects that encourage research, collaboration, and creativity, promoting a well-rounded skill set.
                                        </p>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="serviceBox-1 bg-color2">
                                        <img src={parent} className="img-responsive" alt="toddler childcare activities" />
                                        <h3>Parent Involvement</h3>
                                        <p> We maintain open communication with parents/guardians, providing regular updates on your child's progress and involving families in various aspects of their learning journey.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>


                    <section>
                        <div className='container'>
                            <div className="row">
                                <div className="col-md-12 text-center">
                                    <h2>FAQ'S</h2>
                                </div>
                            </div>
                            <div className="row" style={{ marginTop: "20px" }}>
                                <div className="col-md-12">
                                    <Accordion>
                                        <Accordion.Item eventKey="0">
                                            <Accordion.Header>1. What skills are preschool children actually learning in the dramatic play center?</Accordion.Header>
                                            <Accordion.Body>
                                                The preschoolers' social skills, language skills, and imagination in pretend play are developed through dramatic play centers at Nurture with Care Kids Academy. Children creatively express themselves and collaborate, offering negotiation.
                                            </Accordion.Body>
                                        </Accordion.Item>

                                        <Accordion.Item eventKey="1">
                                            <Accordion.Header>2. What is not one of the key features of a highly structured preschool program?</Accordion.Header>
                                            <Accordion.Body>
                                                Typically, highly structured preschool programs place a lot of emphasis on organized activities, socialization, and cognitive development, but they do not prioritize flexibility and spontaneity which are central to developing creativity among children. Not like the value attached to early childhood development programs by Nurture with Care Kids Academy which is well known for its innovation, some <a href="https://nurturewithcarekidsacademy.com/">daycares in Oak Harbor WA</a> may forget this part and do not give the <a href="https://nurturewithcarekidsacademy.com/programs/toddlers-in-oak-harbor">best toddler daycare</a>.
                                            </Accordion.Body>
                                        </Accordion.Item>

                                        <Accordion.Item eventKey="2">
                                            <Accordion.Header>3. How do preschool programs account for all children in an emergency?</Accordion.Header>
                                            <Accordion.Body>
                                                There are emergency procedures ready to ensure all children are safe at Nurture with Care Kids Academy. These procedures at our preschool programs near Oak Harbor include evacuation drills or even head count protocols.
                                            </Accordion.Body>
                                        </Accordion.Item>

                                        <Accordion.Item eventKey="3">
                                            <Accordion.Header>4. How to apply for the preschool program?</Accordion.Header>
                                            <Accordion.Body>
                                                To enroll in our preschool learning center, visit our website or contact us directly. You will need to fill out and submit the online application form and any other necessary papers. From that point, we will guide you through everything while you ask questions.
                                            </Accordion.Body>
                                        </Accordion.Item>

                                        <Accordion.Item eventKey="4">
                                            <Accordion.Header>5. What is the duration of the preschool program?</Accordion.Header>
                                            <Accordion.Body>
                                                The duration of our preschools in Oak Harbor WA, is from 9 am to 4 pm. This time frame allows for a complete curriculum in varied subjects and activities that promote intellectual development, social skills, and emotional well-being.
                                            </Accordion.Body>
                                        </Accordion.Item>

                                        <Accordion.Item eventKey="5">
                                            <Accordion.Header>6. What are some tips for preparing your child for preschool?</Accordion.Header>
                                            <Accordion.Body>
                                                Some things you can do to prepare your child for early learning preschool include having routines, reading books on school-going, and encouraging independence in simple tasks like dressing or eating. Just before the child begins attending preschool, invite friends over so that they may become familiar with strangers.
                                            </Accordion.Body>
                                        </Accordion.Item>

                                        <Accordion.Item eventKey="6">
                                            <Accordion.Header>7. What are some common concerns that parents have about preschool?</Accordion.Header>
                                            <Accordion.Body>
                                                Parents' common issues about Oak Harbor preschool education include separation anxiety, academic preparation, safety, and socialization. They may be concerned whether their child will fit in the new setting and mingle with his mates as expected to develop himself. The concern for separation anxiety, academic readiness, safety, and socialization among parents who take their children to Oak Harbor preschool education is addressed by Nurture with Care Kids Academy through open communication that eases their worry about their child’s adjustment and development.
                                            </Accordion.Body>
                                        </Accordion.Item>

                                        <Accordion.Item eventKey="7">
                                            <Accordion.Header>8. What are some signs that your child has difficulty adjusting to preschool?</Accordion.Header>
                                            <Accordion.Body>
                                                Some signals that your kid might have problems adjusting to preschool consist of being overly possessive at drop-off time, withdrawing from activities or companions while the <a href="https://nurturewithcarekidsacademy.com/programs/young-toddlers-in-oak-harbor">early childhood development programs</a>, excessive crying or tantrums, physical symptoms such as headaches or stomach aches, and developmental regression signs. Early intervention is possible by speaking to teachers and observing your children's behavior.
                                            </Accordion.Body>
                                        </Accordion.Item>
                                    </Accordion>
                                </div>
                            </div>
                        </div>
                    </section>
                </Layout>
            </div>
        </>
    )
}

export default Preschool