import { useState, useEffect } from 'react';
import "./customNavbar.css"

import logo from '../assets/images/logo/logo-nurture.png'
import { HashLink } from 'react-router-hash-link';
import { Link, useNavigate } from "react-router-dom"

import icon from "../assets/images/Polygon 1.svg"

const NavbarNav = () => {

    const [activeClass, setActiveClass] = useState('')
    const [show, setShow] = useState('none');
    const [showNews, setShowNews] = useState('none');
    const [showProgram, setShowProgram] = useState('none');



    // useEffect(() => {
    //     // Add click event listeners to toggle dropdowns
    //     const dropdownItems = document.querySelectorAll('.custom-dropdown-item > a');
    //     dropdownItems.forEach(item => {
    //         item.addEventListener('click', (event) => {
    //             event.preventDefault();
    //             const submenu = item.nextElementSibling;
    //             if (submenu.style.display === 'block') {
    //                 submenu.style.display = 'none';
    //             } else {
    //                 submenu.style.display = 'block';
    //             }
    //         });
    //     });

    //     // Close dropdown if clicked outside
    //     document.addEventListener('click', (event) => {
    //         if (!event.target.closest('.custom-nav-item')) {
    //             const dropdowns = document.querySelectorAll('.custom-dropdown, .custom-dropdown-submenu');
    //             dropdowns.forEach(dropdown => {
    //                 dropdown.style.display = 'none';
    //             });
    //         }
    //     });
    // }, []);

    const navigate = useNavigate();


    const changeClass = () => {
        setActiveClass(activeClass ? '' : 'in')
    }

    const showDropdown = (e) => {
        setShow('block');
    }
    const hideDropdown = e => {
        setShow('none');
    }

    const clickMenu = (e) => {
        e.preventDefault()
        setShow('block');
    }

    const clickNews = (e) => {
        e.preventDefault()
        setShowNews('block');
    }

    const showDropdownNews = (e) => {
        setShowNews('block');
    }
    const hideDropdownNews = e => {
        setShowNews('none');
    }

    const clickProgram = (e) => {
        e.preventDefault()
        setShowProgram('block');
    }

    const showDropdownProgram = (e) => {
        setShowProgram('block');
    }
    const hideDropdownProgram = e => {
        setShowProgram('none');
    }





    return (
        <nav className="navbar navbar-custom bg-white sticky-top" id="navbar-custom">
            <div className="container">
                <div className="navbar-header">
                    <button type="button" className="navbar-toggle" data-toggle="collapse" data-target="#navbar-brand" onClick={changeClass}>
                        <i className="fa fa-bars"></i>
                    </button>
                    <div className="navbar-brand page-scroll pa-0">
                        <Link to="/">
                            <img src={logo} className="img-responsive" alt="Nurture With Care Kids" />
                        </Link>
                    </div>
                </div>
                <div className={`collapse navbar-collapse ${activeClass} `} id="navbar-brand">
                    <ul className="nav navbar-nav page-scroll navbar-right mt-1r">
                        <li><Link to="/" onClick={changeClass}>Home</Link></li>
                        <li><HashLink to="/#about" onClick={changeClass}>About Us</HashLink></li>
                        <li className="dropdown" onMouseEnter={showDropdownProgram} onMouseLeave={hideDropdownProgram}>
                            <Link to='/#services' onClick={clickProgram} className="dropdown-toggle" data-toggle="dropdown">Programs <b className="caret"></b></Link>
                            <ul className="dropdown-menu mt-35r left-0 bg-white mw-fit" style={{ display: showProgram }}>
                                <li className='h-auto text-left'><Link to='/programs/young-toddlers-in-oak-harbor' className='navbar-dropdown-line-height ma-0 w-100 text-start' onClick={changeClass}>Young Toddlers</Link></li>
                                <li className='h-auto text-left'><Link to='/programs/toddlers-in-oak-harbor' className='navbar-dropdown-line-height ma-0 w-100 text-start' onClick={changeClass}>Toddlers</Link></li>
                                <li className='h-auto text-left'><Link to='/programs/preschool-in-oak-harbor' className='navbar-dropdown-line-height ma-0 w-100 text-start' onClick={changeClass}>Preschool </Link></li>
                            </ul>

                        </li>
                        <li><Link to="/enroll" onClick={changeClass}>Enroll Now</Link></li>
                        {/* <li><HashLink to="/#gallery" onClick={changeClass}>Gallery</HashLink></li> */}
                        <li><Link to="/gallery" onClick={changeClass}>Gallery</Link></li>
                        <li><Link to="/career" onClick={changeClass}>Career</Link></li>
                        <li className="dropdown" onMouseEnter={showDropdownNews} onMouseLeave={hideDropdownNews}>
                            <Link to='/' onClick={clickNews} className="dropdown-toggle" data-toggle="dropdown">Newsletters <b className="caret"></b></Link>
                            <ul className="dropdown-menu mt-35r left-0 bg-white mw-fit" style={{ display: showNews }}>
                                {/* <li className='h-auto text-left'><Link to='/newsletter/jun24' className='navbar-dropdown-line-height ma-0 w-100 text-start' onClick={changeClass}>Jun 2024</Link></li>
                                <li className='h-auto text-left'><Link to='/newsletter/may24' className='navbar-dropdown-line-height ma-0 w-100 text-start' onClick={changeClass}>May 2024</Link></li>
                                <li className='h-auto text-left'><Link to='/newsletter/apr24' className='navbar-dropdown-line-height ma-0 w-100 text-start' onClick={changeClass}>Apr 2024</Link></li>
                                <li className='h-auto text-left'><Link to='/newsletter/mar24' className='navbar-dropdown-line-height ma-0 w-100 text-start' onClick={changeClass}>Mar 2024</Link></li>
                                <li className='h-auto text-left'><Link to='/newsletter/feb24' className='navbar-dropdown-line-height ma-0 w-100 text-start' onClick={changeClass}>Feb 2024</Link></li>
                                <li className='h-auto text-left'><Link to='/newsletter/jan24' className='navbar-dropdown-line-height ma-0 w-100 text-start' onClick={changeClass}>Jan 2024</Link></li>
                                <li className='h-auto text-left'><Link to='/newsletter/dec23' className='navbar-dropdown-line-height ma-0 w-100 text-start' onClick={changeClass}>Dec 2023</Link></li>
                                <li className='h-auto text-left'><Link to='/newsletter/nov23' className='navbar-dropdown-line-height ma-0 w-100 text-start' onClick={changeClass}>Nov 2023</Link></li>
                                <li className='h-auto text-left'><Link to='/newsletter/oct23' className='navbar-dropdown-line-height ma-0 w-100 text-start' onClick={changeClass}>Oct 2023</Link></li> */}
                                <li className="dropdown custom-dropdown-item h-auto text-left">
                                    <a href="#" className='custom-dropdown-item-a navbar-dropdown-line-height ma-0 w-100 text-start'><span>2024</span>  <img src={icon} alt="" /></a>
                                    <ul className="dropdown-menu custom-dropdown-submenu">
                                        <li className='h-auto text-center'><Link to='/newsletter/dec24' className='navbar-dropdown-line-height ma-0 w-100' onClick={changeClass}>December</Link></li>
                                        <li className='h-auto text-center'><Link to='/newsletter/nov24' className='navbar-dropdown-line-height ma-0 w-100' onClick={changeClass}>November</Link></li>
                                        <li className='h-auto text-center'><Link to='/newsletter/oct24' className='navbar-dropdown-line-height ma-0 w-100' onClick={changeClass}>October</Link></li>
                                        <li className='h-auto text-center'><Link to='/newsletter/sep24' className='navbar-dropdown-line-height ma-0 w-100' onClick={changeClass}>September</Link></li>
                                        <li className='h-auto text-center'><Link to='/newsletter/aug24' className='navbar-dropdown-line-height ma-0 w-100' onClick={changeClass}>August</Link></li>
                                        <li className='h-auto text-center'><Link to='/newsletter/jul24' className='navbar-dropdown-line-height ma-0 w-100' onClick={changeClass}>July</Link></li>
                                        <li className='h-auto text-center'><Link to='/newsletter/jun24' className='navbar-dropdown-line-height ma-0 w-100' onClick={changeClass}>June</Link></li>
                                        <li className='h-auto text-center'><Link to='/newsletter/may24' className='navbar-dropdown-line-height ma-0 w-100' onClick={changeClass}>May</Link></li>
                                        <li className='h-auto text-center'><Link to='/newsletter/apr24' className='navbar-dropdown-line-height ma-0 w-100' onClick={changeClass}>April</Link></li>
                                        <li className='h-auto text-center'><Link to='/newsletter/mar24' className='navbar-dropdown-line-height ma-0 w-100' onClick={changeClass}>March</Link></li>
                                        <li className='h-auto text-center'><Link to='/newsletter/feb24' className='navbar-dropdown-line-height ma-0 w-100' onClick={changeClass}>February</Link></li>
                                        <li className='h-auto text-center'><Link to='/newsletter/jan24' className='navbar-dropdown-line-height ma-0 w-100' onClick={changeClass}>January</Link></li>
                                    </ul>
                                </li>
                                <li className="dropdown custom-dropdown-item h-auto text-left">
                                    <a href="#" className=' custom-dropdown-item-a navbar-dropdown-line-height ma-0 w-100 text-start'><span>2023</span>  <img src={icon} alt="" /></a>
                                    <ul className="dropdown-menu custom-dropdown-submenu" >
                                        <li className='h-auto text-center'><Link to='/newsletter/dec23' className='navbar-dropdown-line-height ma-0 w-100' onClick={changeClass}>December</Link></li>
                                        <li className='h-auto text-center'><Link to='/newsletter/nov23' className='navbar-dropdown-line-height ma-0 w-100' onClick={changeClass}>November</Link></li>
                                        <li className='h-auto text-center'><Link to='/newsletter/oct23' className='navbar-dropdown-line-height ma-0 w-100' onClick={changeClass}>October</Link></li>
                                    </ul>
                                </li>
                            </ul>

                        </li>
                        <li className="dropdown" onMouseEnter={showDropdown} onMouseLeave={hideDropdown}>
                            <Link to='/' onClick={clickMenu} className="dropdown-toggle" data-toggle="dropdown">Contact Us <b className="caret"></b></Link>
                            <ul className="dropdown-menu mt-35r left-0 bg-white mw-fit" style={{ display: show }}>
                                <li className='h-auto text-left'><Link to='/contact' className='navbar-dropdown-line-height ma-0 w-100 text-start' onClick={changeClass}>Contact</Link></li>
                                <li className='h-auto text-left'><Link to="https://tinyurl.com/nurturewithcarekidsacademy" rel="noreferrer" target='_blank' className='navbar-dropdown-line-height ma-0 w-100 text-start' onClick={changeClass}>Request for Childcare</Link></li>
                            </ul>
                        </li>
                    </ul>
                </div>
            </div>
        </nav>
    )
}

export default NavbarNav